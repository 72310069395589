@import "./_sass-essentials/essentials.scss";

.pt--tw-layout-row {
  &.width-content-wide {
    @include l-cont-width($c-xl: false);
    margin-left: auto;
    margin-right: auto;

    .grid-cols-wrap {
      .paragraph-inner.boxed-width-content {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  & > .paragraph-inner {
    & > .grid-cols-wrap {
      @include fg-row();
    }
  }

  .grid-cols-wrap {
    > .paragraph,
    > .paragraph:last-child {
      @include mq(0) {
        margin-bottom: 0;
      }
    }
  }

  //&:not(.mobile-order-inverse) {
    .grid-cols-wrap {
      > .pt--tw-layout-col.col-xxs-12 + .pt--tw-layout-col,
      > .pt--tw-layout-col.col-xs-12 + .pt--tw-layout-col {
        @include mq(false, $bp-xs-max) {
          margin-top: $co-mob;
        }
      }
      > .pt--tw-layout-col.col-sm-12 + .pt--tw-layout-col {
        @include mq($bp-sm-min, $bp-sm-max) {
          margin-top: $co-desk;
        }
      }
      > .pt--tw-layout-col.col-md-12 + .pt--tw-layout-col {
        @include mq($bp-md-min, $bp-md-max) {
          margin-top: $co-lg;
        }
      }
      > .pt--tw-layout-col.col-lg-12 + .pt--tw-layout-col,
      > .pt--tw-layout-col.col-xl-12 + .pt--tw-layout-col {
        @include mq($bp-lg-min) {
          margin-top: $co-xl;
        }
      }
    }
  //}

  /*&.mobile-order-inverse {
    .grid-cols-wrap {
      > .pt--tw-layout-col + .col-xxs-12,
      > .pt--tw-layout-col + .col-xs-12 {
        @include mq(false, $bp-xs-max) {
          margin-bottom: $co-mob;
        }
      }
      > .pt--tw-layout-col + .col-sm-12 {
        @include mq($bp-sm-min, $bp-sm-max) {
          margin-bottom: $co-desk;
        }
      }
      > .pt--tw-layout-col + .col-md-12 {
        @include mq($bp-md-min, $bp-md-max) {
          margin-bottom: $co-lg;
        }
      }
      > .pt--tw-layout-col + .col-lg-12,
      > .pt--tw-layout-col + .col-xl-12 {
        @include mq($bp-lg-min) {
          margin-bottom: $co-xl;
        }
      }
    }
  }*/

  &.pg-has-bg-img {
    &.content-light-on-dark-bg,
    &.content-dark-on-light-bg {
      color: $c-darkbg-txt;

      h1, h2, h3, h4, h5 {
        color: $c-darkbg-txt;
      }

      a:not(.btn) {
        color: $c-darkbg-txt;

        &:focus,
        &:hover {
          color: $c-darkbg-link-hov;
        }
      }
    }
  }

  /*@include mq(false, $bp-sm-max) {
    &.mobile-order-inverse {
      .grid-cols-wrap {
        > .paragraph:first-child {
          order: 10;
        }

        > .paragraph:last-child {
          order: -10;
        }
      }
    }
  }*/

  @include mq(false, $bp-xs-max) {
    &.pg-has-bg-img {
      background-image: none !important;
    }
  }

  @include mq($bp-sm-min) {
    &.pg-has-bg-img {
      padding-top: 50px;
      padding-bottom: 50px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  @include mq(1350px) {
    &.width-content-wide {
      max-width: 1320px;
    }
  }
}
