.pt--tw-layout-row {
  /*&.mobile-order-inverse {
    .grid-cols-wrap {
      > .pt--tw-layout-col + .col-xxs-12,
      > .pt--tw-layout-col + .col-xs-12 {
        @include mq(false, $bp-xs-max) {
          margin-bottom: $co-mob;
        }
      }
      > .pt--tw-layout-col + .col-sm-12 {
        @include mq($bp-sm-min, $bp-sm-max) {
          margin-bottom: $co-desk;
        }
      }
      > .pt--tw-layout-col + .col-md-12 {
        @include mq($bp-md-min, $bp-md-max) {
          margin-bottom: $co-lg;
        }
      }
      > .pt--tw-layout-col + .col-lg-12,
      > .pt--tw-layout-col + .col-xl-12 {
        @include mq($bp-lg-min) {
          margin-bottom: $co-xl;
        }
      }
    }
  }*/
  /*@include mq(false, $bp-sm-max) {
    &.mobile-order-inverse {
      .grid-cols-wrap {
        > .paragraph:first-child {
          order: 10;
        }

        > .paragraph:last-child {
          order: -10;
        }
      }
    }
  }*/
}

.pt--tw-layout-row.width-content-wide {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 768px) {
  .pt--tw-layout-row.width-content-wide {
    max-width: 750px;
  }
}

@media screen and (min-width: 992px) {
  .pt--tw-layout-row.width-content-wide {
    max-width: 990px;
  }
}

@media screen and (min-width: 1200px) {
  .pt--tw-layout-row.width-content-wide {
    max-width: 1200px;
  }
}

.pt--tw-layout-row.width-content-wide .grid-cols-wrap .paragraph-inner.boxed-width-content {
  padding-left: 0;
  padding-right: 0;
}

.pt--tw-layout-row > .paragraph-inner > .grid-cols-wrap {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

@media screen and (min-width: 0) {
  .pt--tw-layout-row .grid-cols-wrap > .paragraph,
  .pt--tw-layout-row .grid-cols-wrap > .paragraph:last-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 767px) {
  .pt--tw-layout-row .grid-cols-wrap > .pt--tw-layout-col.col-xxs-12 + .pt--tw-layout-col,
  .pt--tw-layout-row .grid-cols-wrap > .pt--tw-layout-col.col-xs-12 + .pt--tw-layout-col {
    margin-top: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .pt--tw-layout-row .grid-cols-wrap > .pt--tw-layout-col.col-sm-12 + .pt--tw-layout-col {
    margin-top: 30px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .pt--tw-layout-row .grid-cols-wrap > .pt--tw-layout-col.col-md-12 + .pt--tw-layout-col {
    margin-top: 40px;
  }
}

@media screen and (min-width: 1200px) {
  .pt--tw-layout-row .grid-cols-wrap > .pt--tw-layout-col.col-lg-12 + .pt--tw-layout-col,
  .pt--tw-layout-row .grid-cols-wrap > .pt--tw-layout-col.col-xl-12 + .pt--tw-layout-col {
    margin-top: 50px;
  }
}

.pt--tw-layout-row.pg-has-bg-img.content-light-on-dark-bg, .pt--tw-layout-row.pg-has-bg-img.content-dark-on-light-bg {
  color: #FFF;
}

.pt--tw-layout-row.pg-has-bg-img.content-light-on-dark-bg h1, .pt--tw-layout-row.pg-has-bg-img.content-light-on-dark-bg h2, .pt--tw-layout-row.pg-has-bg-img.content-light-on-dark-bg h3, .pt--tw-layout-row.pg-has-bg-img.content-light-on-dark-bg h4, .pt--tw-layout-row.pg-has-bg-img.content-light-on-dark-bg h5, .pt--tw-layout-row.pg-has-bg-img.content-dark-on-light-bg h1, .pt--tw-layout-row.pg-has-bg-img.content-dark-on-light-bg h2, .pt--tw-layout-row.pg-has-bg-img.content-dark-on-light-bg h3, .pt--tw-layout-row.pg-has-bg-img.content-dark-on-light-bg h4, .pt--tw-layout-row.pg-has-bg-img.content-dark-on-light-bg h5 {
  color: #FFF;
}

.pt--tw-layout-row.pg-has-bg-img.content-light-on-dark-bg a:not(.btn), .pt--tw-layout-row.pg-has-bg-img.content-dark-on-light-bg a:not(.btn) {
  color: #FFF;
}

.pt--tw-layout-row.pg-has-bg-img.content-light-on-dark-bg a:not(.btn):focus, .pt--tw-layout-row.pg-has-bg-img.content-light-on-dark-bg a:not(.btn):hover, .pt--tw-layout-row.pg-has-bg-img.content-dark-on-light-bg a:not(.btn):focus, .pt--tw-layout-row.pg-has-bg-img.content-dark-on-light-bg a:not(.btn):hover {
  color: #B3B3B3;
}

@media screen and (max-width: 767px) {
  .pt--tw-layout-row.pg-has-bg-img {
    background-image: none !important;
  }
}

@media screen and (min-width: 768px) {
  .pt--tw-layout-row.pg-has-bg-img {
    padding-top: 50px;
    padding-bottom: 50px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@media screen and (min-width: 1350px) {
  .pt--tw-layout-row.width-content-wide {
    max-width: 1320px;
  }
}
